import 'bootstrap';

$(document).ready(function() {
  $(document).on('click', function(e){
    if (!$(e.target).closest('.header__dropdown.onclick').length) {
      $(".header__dropdown").removeClass('open');
    }
  });

  $('.header__dropdown.onclick .header__dropdown__toggle').on('click', function (e) {
    console.log('click');
    if($(this).parent('.header__dropdown').hasClass('open')){
      $(this).parent('.header__dropdown').toggleClass('open');
    } else {
      $(".header__dropdown").removeClass('open');
      $(this).parent('.header__dropdown').toggleClass('open');
    }
    e.stopPropagation();
  });

  $('.header__dropdown.onhover').on('mouseenter', function () {
    $('.header__dropdown').removeClass('open');
  });
});
