import { createApp } from "vue/dist/vue.esm-bundler";
import axios from "axios";
import VueAxios from "vue-axios";

if (document.getElementById("tab_notes") !== null) {
  const product_notes = createApp({
    data() {
      return {
        note: NOTE,
        saving: false,
        autosave_timer: 0,
      };
    },
    methods: {
      getData() {
        const vm = this;
        this.$http
          .get("/ajax/product/note/" + PRODUCT_ID)
          .then(function (response) {
            vm.note = response.data.note;
            vm.saving = false;
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      saveNote() {
        const vm = this;

        if (this.autosave_timer > 0) {
          clearTimeout(this.autosave_timer);
        }

        this.saving = true;

        this.$http
          .post("/ajax/product/note/" + PRODUCT_ID, { note: this.note })
          .then(function (response) {
            vm.note = response.data.note;
            vm.saving = false;
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      autoSaveNote() {
        //autosave after 10 sec
        if (this.autosave_timer > 0) {
          clearTimeout(this.autosave_timer);
        }
        this.autosave_timer = setTimeout(() => this.saveNote(), 10000);
      },
    },
    created() {
      // this.getData();
    },
    mounted() {},
  })
    .use(VueAxios, axios)
    .mount("#tab_notes");
}
