import 'bootstrap';

$(document).ready(function() {
  $('.menu .layer1 a').on('mouseenter', function () {
    var categoryId = $(this).data('category-id');

    $('.menu .layer1 > a').removeClass('active');
    $('.menu .layer2 > div > a').removeClass('active');
    $('.menu .layer3 > div').addClass('d-none');
    $(this).addClass('active');

    $('.menu .layer2 > div').addClass('d-none');
    $('.menu .layer4 > div').addClass('d-none');
    $('#category_' + categoryId).removeClass('d-none');
  });

  $('.menu .layer2 > div a').on('mouseenter', function () {
    var categoryId = $(this).data('category-id');

    $('.menu .layer2 > div > a').removeClass('active');
    $(this).addClass('active');

    $('.menu .layer3 > div').addClass('d-none');
    $('.menu .layer4 > div').addClass('d-none');

    $('#category_' + categoryId).removeClass('d-none');
  });

  $('.menu .layer3 > div a').on('mouseenter', function () {
    var categoryId = $(this).data('category-id');

    $('.menu .layer3 > div > a').removeClass('active');
    $(this).addClass('active');

    $('.menu .layer4 > div').addClass('d-none');

    $('#category_' + categoryId).removeClass('d-none');
  });
});

//mobile
$(document).ready(function() {
  $('#hamburger__icon').on('click', function() {
    if($('.header__menu').hasClass('active')) {
      $('.header__menu').removeClass('active');
      //overflow on body
      $('body').css('overflow', 'auto');
    } else {
      $('.header__menu').addClass('active');
      //overflow on body
      $('body').css('overflow', 'hidden');
    }
  });
});

